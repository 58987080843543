import { BASE_URL } from '../../utils/constants/url'
import axios from 'axios'
import generalHeader from '../../utils/constants/generalHeader'

const getPostSample = async (data) => {
  const { orgId, imageSample: sampleImage } = data
  const url = `${BASE_URL}/social/post-sample?orgId=${orgId}`

  const response = await axios.post(
    url,
    { sampleImage },
    {
      headers: generalHeader(localStorage.getItem('token')),
    },
  )

  return response?.data
}

export default getPostSample
